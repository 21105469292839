<template>
  <ui-component-modal
    modalTitle="Copy reservation"
    modalSize="large"
    cssClassModalBody="is-hidden-overflow no-padding"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="buttonClickHandler"
    :hideFooterButtons="isSavingSuccess || !showInput"
    :disableSaveButton="!enableCopyButton"
    :showModal="showModal"
    :buttonLabelConfirm="!copyFinalized ? 'Copy' : 'Close'"
    @closeModal="onClickCancel"
    @modalBodySize="(val) => { modalBodySize = val }"
  >
    <template v-slot:content>
      <div class="slider-wrapper">
        <transition-group :name="back ? 'slideback' : 'slide'">
          <div
            v-if="showInput"
            :key="1"
            class="slideContent slideContent-dateSelector"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <div
              v-if="reservation.MeetingtypeId === 1"
              class="has-padding wrapper-dateSelector"
            >
              <div class="field-date field ">
                <v-date-picker
                  mode="single"
                  v-model="startDate"
                  :value="formatDates(startDate)"
                  :columns="2"
                  :is-inline="true"
                >
                  <input
                    type="text"
                    class="input"
                    :id="'datepicker-trigger' + fieldId"
                    readonly
                    :value="formatDates(startDate)"
                  />
                </v-date-picker>
              </div>

              <div>
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <ui-loader
                    key="loader"
                    v-if="isCheckingDate"
                  />

                  <table
                    key="result"
                    v-else
                    class="table-spaces table is-fullwidth is-striped"
                  >
                    <tbody>
                      <tr
                        v-for="(meetingspace, index) in meetingspaces"
                        :key="meetingspace.Id"
                      >
                        <td v-if="
                              !showSpaceAlternatives ||
                                selectedSpace.SpaceId === meetingspace.SpaceId
                            ">
                          {{ meetingspace.SpaceName }}
                        </td>
                        <td v-if="
                              !showSpaceAlternatives ||
                                selectedSpace.SpaceId === meetingspace.SpaceId
                            ">
                          {{ meetingspace.Seats }}x
                        </td>
                        <td v-if="
                              !showSpaceAlternatives ||
                                selectedSpace.SpaceId === meetingspace.SpaceId
                            ">
                          {{ meetingspace.SettingId | getSettingName }}
                        </td>
                        <td v-if="
                              !showSpaceAlternatives ||
                                selectedSpace.SpaceId === meetingspace.SpaceId
                            ">
                          <div>
                            <span>{{ meetingspace.PricePerSeat | toCurrency }}</span>
                          </div>

                          <div
                            v-if="
                                !showSpaceAlternatives &&
                                  meetingspace.PricePerSeat !==
                                    meetingspace.NewPrice
                              "
                            class="is-size-7"
                          >
                            <span>
                              {{ $t('Components.Reservations.ModalCopyReservation.Label_SuggestedPrice') }}
                              {{ meetingspace.NewPrice | toCurrency }}
                            </span>

                            <a
                              @click="applyNewSpacePrice(index)"
                              v-text="$t('Components.Reservations.ModalCopyReservation.Link_Apply')"
                            ></a>
                          </div>
                        </td>
                        <td v-if="
                              !showSpaceAlternatives ||
                                selectedSpace.SpaceId === meetingspace.SpaceId
                            ">
                          <div v-if="meetingspace.IsAvailable">
                            <span class="icon is-success has-text-success is-size-5">
                              <font-awesome-icon :icon="['fas', 'check']" />
                            </span>
                            <span
                              v-if="!meetingspace.IsAvailable"
                              class="icon is-danger has-text-danger is-size-5"
                            >
                              <font-awesome-icon :icon="['fas', 'times']" />
                            </span>
                          </div>

                          <div
                            v-if="!meetingspace.IsAvailable"
                            class="notification is-warning"
                          >
                            <ul>
                              <li
                                v-if="showWarning(meetingspace.CheckString, 0)"
                                v-text="$t('Components.Reservations.ModalCopyReservation.List_Location-is-closed-on-that-day')"
                              ></li>
                              <li
                                v-if="showWarning(meetingspace.CheckString, 1)"
                                v-text="$t('Components.Reservations.ModalCopyReservation.List_Location-is-closed')"
                              ></li>
                              <li v-if="showWarning(meetingspace.CheckString, 2)">
                                <div class="wrapper">
                                  <span v-text="$t('Components.Reservations.ModalCopyReservation.List_Space-is-not-available')"></span>
                                  <span class="has-margin-left">
                                    <a
                                      v-if="!meetingspace.IsAvailable"
                                      @click="setShowSpaceAlternatives(meetingspace)"
                                      class="button is-small is-success"
                                      v-text="$t('Components.Reservations.ModalCopyReservation.Button_Show-alternatives')"
                                    ></a>
                                  </span>
                                </div>
                              </li>
                              <li v-if="showWarning(meetingspace.CheckString, 3)">
                                <div class="wrapper">
                                  <span v-text="$t('Components.Reservations.ModalCopyReservation.List_Minimum-hours-not-met')"></span>
                                  <span class="has-margin-left">
                                    <a
                                      @click="ignoreWarning(meetingspace, 3)"
                                      class="button is-small is-success"
                                      v-text="$t('Components.Reservations.ModalCopyReservation.Button_Ignore-warning')"
                                    ></a>
                                  </span>
                                </div>
                              </li>
                              <li v-if="showWarning(meetingspace.CheckString, 4)">
                                <div class="wrapper">
                                  <span v-text="$t('Components.Reservations.ModalCopyReservation.List_Number-of-seats')"></span>
                                  <span class="has-margin-left">
                                    <a
                                      @click="ignoreWarning(meetingspace, 4)"
                                      class="button is-small is-success"
                                      v-text="$t('Components.Reservations.ModalCopyReservation.Button_Ignore-warning')"
                                    ></a>
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>

                      <tr v-if="allSpacesAvailable">
                        <td v-text="$t('Components.Reservations.ModalCopyReservation.Table_Copy-options')"></td>
                        <td colspan="4">
                          <div class="tabs is-toggle is-small">
                            <ul>
                              <li :class="{ 'is-active': optionOptions === 0 }">
                                <a @click="optionOptions = 0">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_No'
                                      )
                                    }}</span>
                                </a>
                              </li>
                              <li :class="{ 'is-active': optionOptions === 1 }">
                                <a @click="optionOptions = 1">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_Yes'
                                      )
                                    }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>

                      <tr v-if="allSpacesAvailable">
                        <td>
                          {{
                              $t(
                                'Components.Reservations.ModalCopyReservation.Table_Make-part'
                              )
                            }}
                        </td>
                        <td colspan="4">
                          <div class="tabs is-toggle is-small">
                            <ul>
                              <li :class="{ 'is-active': optionSet === 0 }">
                                <a @click="optionSet = 0">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_No'
                                      )
                                    }}</span>
                                </a>
                              </li>
                              <li :class="{ 'is-active': optionSet === 1 }">
                                <a @click="optionSet = 1">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_Yes'
                                      )
                                    }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="allSpacesAvailable">
                        <td>
                          <span :class="{
                                'has-text-grey is-strikethrough':
                                  optionNotes === -1,
                              }">Copy notes</span>
                        </td>
                        <td colspan="4">
                          <div class="tabs is-toggle is-small">
                            <ul>
                              <li :class="{ 'is-active': optionNotes === 0 }">
                                <a @click="optionNotes = 0">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_No'
                                      )
                                    }}</span>
                                </a>
                              </li>
                              <li :class="{ 'is-active': optionNotes === 1 }">
                                <a @click="optionNotes = 1">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_Yes'
                                      )
                                    }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>

                      <tr v-if="allSpacesAvailable && reservation.VoucherId > 0">
                        <td>
                          <span :class="{
                                'has-text-grey-light': optionVoucher !== 0,
                              }">{{
                                $t(
                                  'Components.Reservations.ModalCopyReservation.Table_Apply-voucher'
                                )
                              }}</span>
                        </td>
                        <td>
                          <div class="tabs is-toggle is-small">
                            <ul>
                              <li :class="{ 'is-active': !applyVoucher }">
                                <a @click="applyVoucher = false">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_No'
                                      )
                                    }}</span>
                                </a>
                              </li>
                              <li :class="{ 'is-active': applyVoucher }">
                                <a @click="applyVoucher = true">
                                  <span>{{
                                      $t(
                                        'Components.Reservations.ModalCopyReservation.Radio_Yes'
                                      )
                                    }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </transition>
              </div>
            </div>

            <div v-if="reservation.MeetingtypeId === 3"></div>

            <Message
              v-if="message.length > 0"
              :message="message"
              :type="'is-danger'"
            />
          </div>

          <!-- Show alternatives -->
          <div
            v-if="showSpaceAlternatives"
            key="alternativeSpaces"
            class="slideContent-alternatives slideContent"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <div class="backbar has-padding no-padding-bottom">
              <a @click="setShowInput">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </span>
                <span v-text="$t('Form.Control.Back')"></span>
              </a>
              <hr />
            </div>

            <div class="columns-alternatives columns is-gapless is-mobile is-vcentered">
              <div class="column is-two-fifths">
                <ReservationSpaceCard :reservationSpace="selectedSpace" />
              </div>
              <div class="column has-text-centered">
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
              </div>
              <div
                class="column column-alternatives is-two-fifths"
                style=""
              >
                <ui-loader v-if="isLoadingAlternatives" />

                <AvailableLocation
                  v-for="location in availablity.Locations"
                  :key="location.Id"
                  :availableLocation="location"
                  :meetingtypeId="reservation.MeetingtypeId"
                  class="list-availableSpaces"
                />
              </div>
            </div>
          </div>

          <!-- Confirmation -->
          <div
            v-if="showConfirmation"
            :key="3"
            class="slideContent slideContent-confirmation"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <h1 v-text="$t('Components.Reservations.ModalCopyReservation.Confirmation_New-reservation-created')"></h1>
            <h3 v-if="newReservation">{{ newReservation.Id }}</h3>
          </div>
        </transition-group>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventbus/event-bus'
import { mapState, mapMutations } from 'vuex'
import format from 'date-fns/format'
import VCalendar from 'v-calendar'

import availabilityProvider from '@/providers/availability'
import reservationProvider from '@/providers/reservation'
import voucherProvider from '@/providers/voucher'

const AvailableLocation = () =>
  import('@/components/Availability/AvailableLocation')
const ReservationSpaceCard = () =>
  import('@/components/Schedule/ReservationSpaceCard')
const Message = () => import('@/components/UI/Message')

Vue.use(VCalendar, {})

export default {
  components: {
    AvailableLocation,
    Message,
    ReservationSpaceCard,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      applyVoucher: false,
      availablity: {},
      back: false,
      copyFinalized: false,
      date: new Date().getTime(),
      dataFormat: 'D MMMM YYYY',
      startDate: null,
      endDate: null,
      fieldId: new Date().getTime(),
      firstLoad: true,
      isCheckingDate: false,
      isLoadingAlternatives: false,
      isSaving: false,
      isSavingSuccess: false,
      meetingspaces: [],
      message: '',
      optionOptions: 0,
      optionSet: 0,
      optionNotes: 0,
      optionVoucher: 0,
      newReservation: null,
      showSpaceAlternatives: false,
      selectedSpace: null,
      showConfirmation: false,
      showInput: true,
      dateChangeCounter: 0,
      voucher: null,
      modalBodySize: {
        width: 0,
        height: 0
      },
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    allSpacesAvailable() {
      let allAvailable = false
      let checkCounter = 0

      if (this.meetingspaces && this.meetingspaces.length > 0) {
        for (let i = 0; i < this.meetingspaces.length; i++) {
          let ms = this.meetingspaces[i]

          if (ms.IsAvailable === true) {
            checkCounter = checkCounter + 1
          }
        }
      }

      if (checkCounter > 0 && checkCounter === this.meetingspaces.length) {
        allAvailable = true
      }

      return allAvailable
    },

    startDateISO() {
      let dateString = ''

      if (this.startDate) {
        dateString = this.$options.filters.dateObjectIsoDateString(
          this.startDate
        )
      }

      return dateString
    },

    endDateISO() {
      let dateString = ''

      if (this.endDate) {
        dateString = this.$options.filters.dateObjectIsoDateString(this.endDate)
      }

      return dateString
    },

    enableCopyButton() {
      return this.allSpacesAvailable
    },
  },

  watch: {
    startDateISO(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (!this.firstLoad && this.meetingtypeId !== 3) {
          this.dateChangeCounter++
          this.checkAvailability()
        }
      }
    },

    endDateISO(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (!this.firstLoad && this.meetingtypeId === 3) {
          this.dateChangeCounter++
          this.checkAvailability()
        }
      }
    },
  },

  created() {
    this.startDate = this.reservation.StartDate
    this.endDate = this.reservation.EndDate

    if (this.reservation.VoucherId > 0) {
      this.getVoucher()
    }

    EventBus.$on('spaceSelected', (space) => {
      this.updateSelectedSpaceWithAlternative(space)
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
  },

  mounted() {
    this.firstLoad = false
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    removeFocusFromInput(elm) {
      elm.target.blur()
    },

    formatDates(startDate = '', endDate = '') {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dataFormat)
      }
      return formattedDates
    },

    getVoucher() {
      let self = this
      voucherProvider.methods
        .getVoucherById(self.reservation.VoucherId)
        .then((response) => {
          if (response.status === 200) {
            self.voucher = response.data
          }
        })
    },

    checkAvailability() {
      if (!this.isCheckingDate && this.dateChangeCounter > 1) {
        this.isCheckingDate = true
        this.meetingspaces = []
        this.showSpaceAlternatives = false

        this.optionOptions = 0
        this.optionSet = 0
        this.optionVoucher = 0

        if (this.reservation.MeetingtypeId < 3) {
          this.endDate = this.startDate
        }

        availabilityProvider.methods
          .copyReservationCheckAvailability(
            this.reservation.Id,
            this.startDateISO,
            this.endDateISO
          )
          .then((response) => {
            this.meetingspaces = response.data

            for (let i = 0; i < this.meetingspaces.length; i++) {
              let m = JSON.parse(JSON.stringify(this.meetingspaces[i]))

              m.NewPrice = m.PricePerSeat
              m.NewPriceTotal = m.PriceTotal

              let mOld = this.reservation.Spaces.find(
                (s) => s.SpaceId === m.SpaceId
              )
              if (mOld) {
                m.PricePerSeat = mOld.PricePerSeat
                m.PriceTotal = mOld.PriceTotal
              }

              Vue.set(this.meetingspaces, i, m)
            }
          })
          .finally(() => {
            this.isCheckingDate = false
          })
      }
    },

    cancelCopy() {
      this.$emit('cancelCopy')
    },

    buttonClickHandler() {
      if (this.copyFinalized) {
        this.onClickCancel()
      } else {
        this.copyReservation()
      }
    },

    applyNewSpacePrice(spaceIndex) {
      this.meetingspaces[spaceIndex].PricePerSeat = this.meetingspaces[
        spaceIndex
      ].NewPrice
      this.meetingspaces[spaceIndex].PriceTotal = this.meetingspaces[
        spaceIndex
      ].NewPriceTotal
    },

    copyReservation() {
      let self = this
      self.isSaving = true

      let criteria = {
        ReservationId: self.reservation.Id,
        StartDate: self.startDateISO,
        EndDate: self.endDateISO,
        ReservationSpaces: self.meetingspaces,
        InSet: self.optionSet === 1,
        CopyOptions: self.optionOptions === 1,
        CopyNotes: self.optionNotes === 1,
        ApplyVoucher: self.applyVoucher,
      }

      reservationProvider.methods
        .copyReservation(criteria)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.StatusCode === 1) {
              self.isSavingSuccess = true

              self.copyFinalized = true
              self.newReservation = response.data.Result

              self.setShowConfirmation()

              if (self.optionSet === 1) {
                if (self.reservation.SetId === 0) {
                  self.reservation.SetId = self.reservation.Id

                  self.reservation.ReservationSetIds.push({
                    Id: self.reservation.Id,
                    Date: self.reservation.StartDate,
                  })
                }

                self.reservation.ReservationSetIds.push({
                  Id: self.newReservation.Id,
                  Date: self.newReservation.StartDate
                })

                self.setReservation(self.reservation)

                setTimeout(() => {
                  self.onClickCancel()
                }, 1500)
              }
              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            } else {
              self.message = response.data.Message
            }
          }
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    setShowSpaceAlternatives(space) {
      let self = this

      if (!self.isLoadingAlternatives) {
        self.availablity = {}
        self.isLoadingAlternatives = true

        self.back = false

        self.showInput = false
        self.selectedSpace = space
        self.selectedSpace.LocationId = this.reservation.LocationId
        self.showSpaceAlternatives = true

        let countryId = 0
        let channelId = self.reservation.ChannelId
        let searchterm = ''
        let settingId = 0

        let startDateISO = self.$options.filters.dateObjectIsoDateString(
          space.StartDate
        )
        let endDateISO = self.$options.filters.dateObjectIsoDateString(
          space.EndDate
        )

        availabilityProvider.methods
          .getAvailability(
            startDateISO,
            endDateISO,
            channelId,
            countryId,
            space.LocationId,
            searchterm,
            space.MeetingTypeIdeetingtypeId,
            space.Seats,
            space.StartMinutes,
            space.EndMinutes,
            settingId
          )
          .then((response) => {
            self.availablity = response.data
          })
          .finally(() => {
            self.isLoadingAlternatives = false
          })
      }
    },

    updateSelectedSpaceWithAlternative(space) {
      let self = this

      let spaceIndex = self.meetingspaces.findIndex(
        (m) => m.Id === self.selectedSpace.Id
      )
      if (spaceIndex > -1) {
        let newSpace = self.meetingspaces[spaceIndex]

        newSpace.SpaceId = space.SpaceId
        newSpace.SpaceName = space.SpaceName
        newSpace.SpaceImage = space.SpaceImage
        newSpace.StartDate = space.StartDate
        newSpace.StartMinutes = space.StartMinutes
        newSpace.EndDate = space.EndDate
        newSpace.EndMinutes = space.EndMinutes
        newSpace.SettingId = space.SettingId
        newSpace.PricePerSeat = space.PricePerSeat
        newSpace.PriceTotal = space.PriceTotal
        newSpace.Hash = space.Hash

        Vue.set(self.meetingspaces, spaceIndex, newSpace)

        self.ignoreWarning(self.meetingspaces[spaceIndex], 2)

        self.setShowInput()
      }
    },

    showWarning(checkString, index) {
      let checks = checkString.split('#')

      return Number(checks[index]) === 0
    },

    ignoreWarning(meetingspace, index) {
      let checks = meetingspace.CheckString.split('#')
      checks[index] = 1

      let checkIndex = checks.findIndex((c) => c === 0)
      if (checkIndex === -1) {
        meetingspace.IsAvailable = true
      }

      meetingspace.CheckString = checks.join('#')
    },

    setShowInput() {
      this.back = true
      this.showInput = true
      this.showSpaceAlternatives = false
      this.availablity = {}
      this.selectedSpace = null
    },

    setShowConfirmation() {
      this.showInput = false
      this.showSpaceAlternatives = false
      this.showConfirmation = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";

.slider-wrapper {
  overflow: hidden;
  height: 80vh;
  max-height: 600px;

  .slideContent {
    height: 100%;
    overflow: hidden;

    &.slideContent-dateSelector {
      overflow: auto;
      .wrapper-dateSelector {
        .table-spaces {
          .notification {
            padding: 10px;
            .wrapper {
              display: flex;
              align-items: center;
              span {
                &:first-child {
                  flex-grow: 1;
                  flex-shrink: 1;
                }
              }
            }
          }
        }
      }
    }

    &.slideContent-alternatives {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      .backbar {
        flex-grow: 0;
        flex-shrink: 0;
      }
      .columns-alternatives {
        height: 100%;
        overflow: hidden;

        .column-alternatives {
          overflow: auto;
          height: 100%;
        }
      }
    }
  }
}
</style>
